function matchMedia(size) {
  const sizes = {
    "sm": "(min-width: 601px)",
    "lt-sm": "(max-width: 600px)"
  }

  const match = window.matchMedia(sizes[size])
  return match.matches
}

function scrollTo(target, url = false) {
  window.setTimeout(() => {
    const position = target.offsetTop
    const layout = document.querySelector(".layout")
    const offset = layout ? 
      parseFloat(window.getComputedStyle(layout).getPropertyValue("padding-top")) : 0

    window.scrollTo({
      top: position - offset,
      behavior: "smooth"
    })

    if (url) {
      history.replaceState(null, null, url)
    }
  }, 50)
}

function isMobile() {
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

  if (mobileRegex.test(navigator.userAgent)) {
    return true
  } else {
    false
  }
}

export { matchMedia, scrollTo, isMobile }
