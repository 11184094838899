import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    super.connect()
  }

  refresh(event) {
    const form = this.element
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = "refresh"
    input.value = "true"
    form.appendChild(input)
    form.setAttribute("data-turbo-frame", "_self")

    window.requestAnimationFrame(() => {
      form.requestSubmit()
    })
  }
}
