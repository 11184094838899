import { Controller } from "stimulus"
import { matchMedia, scrollTo } from "./../shared/body"

export default class extends Controller {

  static values = {
    loading: Boolean,
    requestUrl: String
  }

  static targets = ["results"]

  connect() {
    this.beforeFetchRequestHandler = (event) => {
      this.loadingValue = true
    }

    this.frameRenderHandler = (event) => {
      this.loadingValue = false
      const response = event.detail.fetchResponse.response
      this.updateHistory(response.url)

      if (this.hasResultsTarget && matchMedia("lt-sm")) {
        scrollTo(this.resultsTarget)
      }
    }

    this.element.addEventListener("turbo:before-fetch-request", 
      this.beforeFetchRequestHandler)

    this.element.addEventListener("turbo:frame-render", 
      this.frameRenderHandler)

    // if (this.hasRequestUrlValue && document.location != this.requestUrlValue) {
      // this.updateHistory(this.requestUrlValue)
      // this.requestUrlValue = ""
    // }
  }

  disconnect() {
    this.element.removeEventListener("turbo:before-fetch-request", 
      this.beforeFetchRequestHandler)

    this.element.removeEventListener("turbo:frame-render", 
      this.beforeFetchRequestHandler)
  }

  loadingValueChanged() {
    this.element.classList.toggle("archives-index--loading", this.loadingValue)
    const turboAdapter = Turbo.navigator.delegate.adapter

    if (this.loadingValue) {
      turboAdapter.progressBar.value = 0
      turboAdapter.showProgressBar()
    } else {
      turboAdapter.progressBar.hide()
    }
  }

  updateHistory(url) {
    history.replaceState(history.state, "", url)
  }
}
