import { Controller } from "stimulus"
import Tagify from "@yaireo/tagify"

export default class extends Controller {

  static values = {
    url: String,
    enforceWhitelist: Boolean
  }

  connect() {
    let list = []

    this.fetchList()
      .then(response => { list = response })
      .catch(error => { console.log(error) })
      .finally(() => {

        this.tagify = new Tagify(this.element, {
          whitelist: list,
          enforceWhitelist: this.enforceWhitelistValue,
          originalInputValueFormat: a => a.map(i => i.value).join(",")
        })

        // this.syncInput(this.tagify)
        // this.tagify.on("add remove", event => { this.syncInput(this.tagify) })
      })
  }

  async fetchList() {
    if (this.urlValue) {
      const response = await fetch(this.urlValue)

      if (response.ok) {
        const json = await response.json()

        return json.map(object => {
          return object.value
        })
      } else {
        throw Error(response.url, response.statusText)
      }
    } else {
      return []
    }
  }

  // syncInput(tagify) {
  //   const value = tagify.value.map(object => { return object.value })
  //   this.element.value = value.join(", ")
  // }
}
