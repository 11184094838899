import { Controller } from "stimulus"
import { matchmedia, scrollTo } from "./shared/body"

export default class extends Controller {

  static targets = ["header", "scrollToTop", "starting", "cookiesBanner"]

  connect() {
    // document click handler for links' behaviour
    this.clickHandler = (event) => {
      const element = event.target
      const tagname = element.tagName.toLowerCase()

      if (tagname === "a" &&
          (location.hostname === element.hostname || !element.hostname.length)) {

        const anchor = element.href.split("#").pop()
        const target = document.getElementById(element.href.split("#").pop())

        if (anchor && target) {
          event.preventDefault()
          scrollTo(target, `#${anchor}`)
        }
      } else if (tagname === "a" && location.hostname !== element.hostname) {
        event.preventDefault()
        window.open(element.href, "_blank")
      }
    }

    this.element.addEventListener("click", this.clickHandler)

    // oberserver to control display of scrollToTopButton
    const headerObserver = new IntersectionObserver((entries) => {

      if (entries[0].intersectionRatio == 0) {
        this.scrollToTop(true)
      } else {
        this.scrollToTop(false)
      }
    })

    if (this.hasHeaderTarget) {
      headerObserver.observe(this.headerTarget)
    }

    // jump to starting point when screen size is < sm and location has no hash
    if (this.hasStartingTarget && matchMedia("lt-sm") && !location.hash) {
      scrollTo(this.startingTarget)
    }
  }

  disconnect() {
    this.element.removeEventListener("click", this.clickHandler)
  }

  scrollToTop(active) {
    
    if (active && !this.hasScrollToTopTarget) {
      const element = document.createElement("button")
      element.classList.add("scroll-to-top")
      element.setAttribute("data-body-target", "scrollToTop")

      element.addEventListener("click", (event) => {
        event.preventDefault()
        scrollTo(document.body)
      })

      this.element.appendChild(element)
    } else if (!active && this.hasScrollToTopTarget) {
      this.scrollToTopTarget.parentNode.removeChild(this.scrollToTopTarget)
    }
  }

  async cookiesBanner(event) {
    event.preventDefault()

    if (!this.hasCookiesBannerTarget){
      const response = await fetch("/cookies/banner")
      const json = await response.json()
      const html = json["html"]
      this.element.insertAdjacentHTML("beforeend", html)
    }
  }
}
