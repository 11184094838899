import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const input = this.element.querySelector("input")
    const events = ["change", "blur"]
    events.forEach(event => { input.addEventListener(event, this.change) })
  }

  disconnect() {
    const input = this.element.querySelector("input")
    const events = ["change", "blur"]
    events.forEach(event => { input.removeEventListener(event, this.change) })
  }

  change(event) {
    event.preventDefault()
    // event.target.checked = !event.target.checked
    const form = event.target.closest("form")

    if (event.target.checked) {
      const tagName = event.target.tagName.toLowerCase()
      const siblings = form.querySelectorAll(`${tagName}[name="${event.target.name}"]`)

      siblings.forEach(elem => {
        if (elem != event.target) { elem.checked = false }
      })
    }

    form.requestSubmit()
  }
}
