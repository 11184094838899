import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    currentSlideIndex: Number,
    currentSlide: String,
    length: String
  }
  
  initialize() {
    let slides = this.element.children
    slides = Array.from(slides) 

    if (slides.length <= 1) { return false }

    slides.forEach(elem => {
      elem.classList.add("slide")
    })

    this.clickHandler = (event) => {
      const slide = event.target.closest(".slide")

      if (slide) {
        this.next()
      }
    }

    this.element.addEventListener("click", this.clickHandler)

    this.currentSlideIndexValue = this.currentSlideIndexValue  
    this.lengthValue = slides.length
  }

  disconnect() {
    this.element.removeEventListener("click", this.clickHandler)
  }

  currentSlideIndexValueChanged(value) {
    const activeClass = "slide--active"
    let slides = this.element.children
    slides = Array.from(slides)

    slides.forEach(elem => {
      elem.classList.remove(activeClass)
    })

    if (slides.length > 0) {
      slides[value].classList.add(activeClass) 
    }

    this.currentSlideValue = value + 1
    this.lengthValue = slides.length
  }

  next(index = false) {
    const slides = this.element.children
    let nextSlideIndex = index

    if (nextSlideIndex === false) {
      nextSlideIndex = this.currentSlideIndexValue + 1
    }

    this.currentSlideIndexValue = nextSlideIndex >= slides.length ? 0 : nextSlideIndex
  }
}
