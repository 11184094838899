import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    active: Boolean
  }

  static targets = ["fullscreen"]

  connect() {
    this.element.classList.add("slide")

    this.clickHandler = (event) => {
      this.activeValue = !this.activeValue
    }

    this.element.addEventListener("click", this.clickHandler)
  }

  disconnect() {
    this.element.removeEventListener("click", this.clickHandler)
  }

  activeValueChanged(value) {
    this.element.classList.toggle("slide-fullscreen", value)

    if (value && !this.hasFullscreenTarget) {
      const fullscreen = document.createElement("div")
      fullscreen.setAttribute("data-slide-target", "fullscreen")
      fullscreen.classList.add("slide__fullscreen")
      fullscreen.innerHTML = this.element.innerHTML
      this.element.append(fullscreen)

      // trigger window resize to load lazyload images
      window.requestAnimationFrame(() => {
        const resize = new Event("resize")
        window.dispatchEvent(resize)
      })

    } else if (!value && this.hasFullscreenTarget) {
      this.fullscreenTarget.parentNode.removeChild(this.fullscreenTarget)
    }
  }
}
