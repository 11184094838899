import { Controller } from "stimulus"
import { isMobile } from "./../shared/body" 

export default class extends Controller {

  static values = {
    clear: Boolean
  }

  static targets = ["input"]

  connect() {
    const submit = this.element.querySelector("input[type='submit']")
    if (submit && !isMobile()) { submit.parentNode.removeChild(submit) }

    this.clearValue = !this.hasValues

    this.submitHandler = (event) => { this.submit(event) }
    this.element.addEventListener("submit", this.submitHandler)

    this.changeHandler = (event) => { this.clearValue = !this.hasValues }
    this.element.addEventListener("change", this.changeHandler)
  }

  disconnect() {
    this.element.removeEventListener("submit", this.submitHandler)
    this.element.removeEventListener("change", this.changeHandler)
  }

  submit(event = false) {
    this.element.requestSubmit()
  }

  clearValueChanged(value) {
    this.element.classList.toggle("archives-interface--has-values", !value)
  }

  get hasValues() {
    if (!this.hasInputTarget) { return false }

    return Array.from(this.inputTargets).some(element => {

      if (element.type == "checkbox") {
        return element.checked
      } else {
        return element.value
      }
    })
  }

  clear() {
    Array.from(this.inputTargets).forEach(element => {
      if (element.type == "checkbox") {
        element.checked = false
      } else {
        element.value = ""
      }
    })

    this.clearValue = true

    this.element.requestSubmit()
  }
}
