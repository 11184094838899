import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const events = ["change", "blur"]
    events.forEach(event => { this.element.addEventListener(event, this.change) })
  }

  disconnect() {
    const events = ["change", "blur"]
    events.forEach(event => { this.element.removeEventListener(event, this.change) })
  }

  change(event) {
    event.preventDefault()
    const form = event.target.closest("form")
    form.requestSubmit()
  }
}
