import { Controller } from "stimulus"
import { fetchCookies } from "./shared/cookies"

export default class extends Controller {

  static values = {
    host: String,
    id: String
  }

  connect() {
    if (typeof window.referrerUrl === "undefined") {
      window.referrerUrl = window.location.href
    }

    if (typeof window._paq === "undefined") {
      const _paq = window._paq = window._paq || [];

      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      _paq.push(['requireCookieConsent']);
      var u=`//${this.hostValue}/`;
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', this.idValue.toString()]);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    }

    this.turboLoadHandler = (event) => {
      const _paq = window._paq
      const url = window.location.href
      const referrerUrl = window.referrerUrl

      if (typeof _paq !== "undefined" && url != referrerUrl) {

        if (referrerUrl) {
          _paq.push(["setReferrerUrl", referrerUrl])
        }

        _paq.push(["setCustomUrl", window.location.href])
        _paq.push(["setDocumentTitle", document.title])
        _paq.push(["trackPageView"])
        _paq.push(["MediaAnalytics::scanForMedia"])
        _paq.push(["FormAnalytics::scanForForms"])
        _paq.push(["enableLinkTracking"])
      }

      window.referrerUrl = window.location.href
    }

    document.documentElement.addEventListener("turbo:load", this.turboLoadHandler)
    document.documentElement.addEventListener("turbo:frame-load", this.turboLoadHandler)

    this.cookiesSubmitHandler = (event) => {

      this.fetchTrackingCookieConsent()
        .then(value => {
          this.setTrackingCookieConsent(value)
        })
    }

    this.cookiesSubmitHandler(null) // call handler to init tracking cookies consent
    this.element.addEventListener("cookies-submit", this.cookiesSubmitHandler)
  }

  disconnect() {
    this.element.removeEventListener("cookies-submit", this.cookiesSubmitHandler)
    document.documentElement.removeEventListener("turbo:load", this.turboLoadHandler)
    document.documentElement.removeEventListener("turbo:frame-load", this.turboLoadHandler)
  }

  async fetchTrackingCookieConsent() {
    const [json, response] = await fetchCookies()
    return parseInt(json["consent_tracking_cookies"])
  }

  setTrackingCookieConsent(value) {

    if (value === 1) {
      window._paq.push(["rememberCookieConsentGiven"])
    } else {
      window._paq.push(["forgetCookieConsentGiven"])
    }
  }
}
