import { Controller } from "stimulus"

export default class extends Controller {

  clipboard(event) {
    const text = event.target.innerHTML.trim()

    navigator.clipboard.writeText(text, () => {
      window.alert(`Copied ${text} to clipboard.`)
    })
  }
}
