import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    afterSubmit: String
  }

  submit(event) {
    event.preventDefault()

    const csrfToken = document.querySelector("[name='csrf-token']").content
    const url = event.target.action
    const data = new URLSearchParams(new FormData(event.target))

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken
      },
      body: data
    }).then(response => {
      const event = new Event("cookies-submit")
      document.body.dispatchEvent(event)

      if (this.afterSubmitValue == "reload") {
        window.location.reload()
      }
    })
  }
}
