import  { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["settings"]

  static values = {
    settingsVisible: Boolean
  }

  initialize() {
    this.submitHandler = (event) => {
      this.element.parentNode.removeChild(this.element)
    }

    this.element.addEventListener("submit", this.submitHandler)
  }

  disconnect() {
    this.element.removeEventListener("submit", this.submitHandler)
  }

  settingsVisibleValueChanged() {
    this.element.classList.toggle("consent-banner--settings-visible", 
      this.settingsVisibleValue)
  }

  toggleSettingsVisible(event) {
    event.preventDefault()
    this.settingsVisibleValue = !this.settingsVisibleValue
  }
}
