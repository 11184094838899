import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["input", "button"]

  connect() {
    if (!this.hasInputTarget) {
      const input = this.element.querySelector("input")
      input.setAttribute("data-archives--search-target", "input")
      this.input()
    }

    this.inputHandler = (event) => {
      this.input(event)
    }

    if (this.hasInputTarget) {
      this.inputTarget.addEventListener("input", this.inputHandler)
    }
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("input", this.inputHandler)
    }
  }

  input(event = null) {
    const hasValue = this.inputTarget.value.length > 0
    this.element.classList.toggle("--has-value", hasValue)

    if (hasValue && !this.hasButtonTarget) {
      const button = document.createElement("button")
      button.setAttribute("type", "button")
      button.setAttribute("data-archives--search-target", "button")
      button.setAttribute("data-action", "click->archives--search#clear")
      this.element.append(button)
    } else if (!hasValue && this.hasButtonTarget) {
      this.buttonTarget.parentNode.removeChild(this.buttonTarget)
    }
  }

  clear(event) {
    event.preventDefault()
    
    if (this.hasInputTarget) {
      const form = this.inputTarget.closest("form")
      this.inputTarget.value = ""
      this.input()
      form.requestSubmit()
    }
  }
}
